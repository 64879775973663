<template>
    <div style="position: relative">

        <v-container>
            <div class="mt-10">
                <h3>{{ $t("message.label-term-of-service") }}</h3>
                <p>{{ $t("message.label-about-term") }}</p>
                <v-divider style="background-color: black"></v-divider>
                <p class="mt-4 mb-4">
                    {{ $t("message.label-term-of-service-tajimingo") }}
                </p>

                <div>
                    <h4>{{ $t("message.label-about-tajimingo-membership") }}</h4>
                    <!-- <h4></h4> -->
                    <v-divider style="background-color: black"></v-divider>
                    <div :class="showTerm ? 'Tos-all-term': 'Tos-layout'">
                        <v-container>
                            <span class="pb-4 paragraph-term">
                                ECモール「Tajimingo」利用規約
                            </span>

                            <div>
                                <div class="pt-3 pb-3">
                                    サービスをご利用のみなさまへ
                                </div>
                                <span style="white-space: pre-line" class="pt-2 pb-2">
                                    株式会社トーカイ薬局(以下「当社」といいます)
                                    が運営するECモール「Tajimingo」
                                    (以下「本サービス」といいます)
                                    の利用規約を以下の通り定めます。
                                    本サービスの利用に際しては、
                                    利用規約の全文をお読みいただいたうえで、
                                    同意いただく必要があります。
                                </span>

                                <div class="pt-3 pb-3">
                                    第1条(定義)
                                </div>

                                <p class="paragraph-term">利用規約において、次の用語は、次の各号にそれぞれ定める意味で用いるものとします。</p>
                                <p class="paragraph-term">(1)「利用者」:モールにアクセスし、ショップで商品等の提供を受ける個人または法人。但し、商品等の配送先を岐阜県多治見市内の住所を指定できる場合に限ります。</p>
                                <p class="paragraph-term">(2)「出店者」:モールに出店することを当社に申し込み、当社が出店を認めた個人または法人</p>
                                <p class="paragraph-term">(3)「会員」:当社が定める手続に従い、利用規約に同意の上、入会の申し込みを行った者</p>
                                <p class="paragraph-term">(4)「モール」:当社が、インターネット上に運営する、商品等を提供するために必要な機能を有するシステム全体(ハードウェアおよびソフトウェアを含む)</p>
                                <p class="paragraph-term">(5)「ショップ」:モール上で出店者が商品等を利用者に対して提供するためのサイト</p>
                                <p class="paragraph-term">(6)「商品等」:出店者がショップで利用者に提供する物品またはサービス</p>
                                <p class="paragraph-term">(7)「本サービス」:当社がモールを通じて利用者に対し提供する、第3条に定めるサービス</p>
                                <p class="paragraph-term">(8)「会員情報」:会員が当社ならびに出店者に開示した会員の属性に関する情報および会員の取引に関する履歴等の情報</p>

                                <div class="pt-3 pb-3">
                                第2条(契約の成立等)
                                </div>

                                <p class="paragraph-term">1　本規約は、モールおよび本サービスの利用に関し、当社および利用者に適用されます。</p>
                                <p class="paragraph-term">2　利用者は、本利用規約内容を承諾の上、モールおよび本サービスの利用を申込みます。</p>
                                <p class="paragraph-term">3　モールおよび本サービスの利用契約は、当社が、前項の申込を承諾したときに成立します。</p>

                                <div class="pt-3 pb-3">
                                第3条(本サービス)
                                </div>

                                <p class="paragraph-term">1.当社がモールにより提供する本サービスは、次のとおりとします。</p>
                                <p class="paragraph-term">(1)出店者が提供する商品等についてのオンラインによる情報の提供</p>
                                <p class="paragraph-term">(2)利用者の出店者に対する商品等に関する注文情報の転送</p>
                                <p class="paragraph-term">2.当社は利用者に通知することなく、いつでも本サービスの内容を追加または削除することができるものとし、当該追加または削除により利用者に損害が生じても一切責任を負わないものとします。</p>
                                <p class="paragraph-term">3.利用者は本サービスを私的にのみ利用するものとし、本サービスを用いて営業を行わないものとします。</p>

                                <div class="pt-3 pb-3">
                                第4条(サービスの中断・停止等)
                                </div>

                                <p class="paragraph-term">1.当社は、本サービスの稼動状態を良好に保つために、次の各号に該当する場合、事前に通知することなく、本サービスの全てあるいは一部の提供を停止または中断をすることがあります。</p>
                                <p class="paragraph-term">(1)システムの点検または保守のために必要な場合</p>
                                <p class="paragraph-term">(2)システムに負荷が集中した場合</p>
                                <p class="paragraph-term">(3)火災、停電、第三者による妨害行為などによりシステムの運用が困難になった場合</p>
                                <p class="paragraph-term">(4)その他、やむをえずシステムの停止または中断が必要と当社が判断した場合</p>
                                <p class="paragraph-term">2.当社は、本条に基づき当社が行った措置により利用者に生じた損害について一切の責任を負いません。</p>

                                <div class="pt-3 pb-3">
                                第5条(サービスの変更・廃止)
                                </div>

                                <p class="paragraph-term">1.当社は、利用者に事前に通知することにより本サービスの全部または一部を終了することができるものとします。</p>
                                <p class="paragraph-term">2.当社は、本条に基づき当社が行った措置により利用者に生じた損害について一切の責任を負いません。</p>

                                <div class="pt-3 pb-3">
                                第6条(禁止事項)
                                </div>

                                <p class="paragraph-term">1.利用者は、本サービスの利用において以下の各号に定める行為をしてはならないものとします。</p>
                                <p class="paragraph-term">(1)法令または利用規約、Tajimingo のお買い物上のご注意、その他の規約等に違反すること</p>
                                <p class="paragraph-term">(2)当社、出店者およびその他の第三者の権利、利益、名誉等を損ねること</p>
                                <p class="paragraph-term">(3)公の秩序または公序良俗に反すること</p>
                                <p class="paragraph-term">(4)反社会的勢力に対する利益供与その他の協力をすること</p>
                                <p class="paragraph-term">(5)犯罪による収益の移転行為およびこれを助長、幇助等すること</p>
                                <p class="paragraph-term">(6)第三者へのなりすまし、または意図的に虚偽の情報を送信すること</p>
                                <p class="paragraph-term">(7)購入する意図なく商品の購入を申し込むこと</p>
                                <p class="paragraph-term">(8)正当な理由なく商品を受け取らないこと</p>
                                <p class="paragraph-term">(9)正当な理由なく返品等をすること</p>
                                <p class="paragraph-term">(10)本サービスの運営を妨げること</p>
                                <p class="paragraph-term">(11)当社のサーバその他のコンピュータに不正にアクセスすること</p>
                                <p class="paragraph-term">(12)コンピューターウィルス等有害なプログラムを、当サービスに関連して使用、または提供すること</p>
                                <p class="paragraph-term">(13)パスワードを第三者に貸与・譲渡すること、または第三者と共用すること</p>
                                <p class="paragraph-term">(14)その他当社が不適切と判断すること</p>
                                <p class="paragraph-term">2.前項各号に定める行為によって利用者に生じた損害については、当社は一切責任を負いません。 </p>
                                <p class="paragraph-term">3.利用者が第1項に違反したことによって、当社に損失ないし損害が生じた場合、利用者は、当社に対し、違反したことにより利用者が得た利益を返還、また当社に生じた損害を、直ちに賠償しなければなりません。</p>

                                <div class="pt-3 pb-3">
                                第7条(反社会的勢力の排除)
                                </div>

                                <p class="paragraph-term">1.利用者は、利用者が、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者(以下これらを「暴力団員等」という。)に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。</p>
                                <p class="paragraph-term">(1)暴力団員等が経営を支配していると認められる関係を有すること</p>
                                <p class="paragraph-term">(2)暴力団員等が経営に実質的に関与していると認められる関係を有すること</p>
                                <p class="paragraph-term">(3)自己、自社もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること</p>
                                <p class="paragraph-term">(4)暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること</p>
                                <p class="paragraph-term">(5)役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき</p>
                                <p class="paragraph-term">関係を有すること</p>
                                <p class="paragraph-term">2.利用者は、利用者自ら、または第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。</p>
                                <p class="paragraph-term">(1)暴力的な要求行為</p>
                                <p class="paragraph-term">(2)法的な責任を超えた不当な要求行為</p>
                                <p class="paragraph-term">(3)取引に関して、脅迫的な言動をし、または暴力を用いる行為</p>
                                <p class="paragraph-term">(4)風説を流布し、偽計を用いまたは威力を用いて当社の信用を毀損し、または当社の業務を妨害する行為</p>
                                <p class="paragraph-term">(5)その他前各号に準ずる行為</p>
                                <p class="paragraph-term">3.利用者は、利用者が暴力団員等もしくは第1項各号のいずれかに該当し、もしくは前項各号のいずれかに該当する行為をし、または第1項の規定に基づく表明・確約に関して虚偽の申告をしたことが判明し、利用者との取引を継続することが不適切であると当社が判断する場合には、当社からの通知により本契約を解約されたとしても異議はないものとします。</p>
                                <p class="paragraph-term">4.前項の規定の適用により、利用者に損害が生じた場合にも、当社になんらの請求をしません。また、当社に損害が生じたときは、利用者がその責任を負うものとします。</p>

                                <div class="pt-3 pb-3">
                                第8条(通知)
                                </div>

                                <p class="paragraph-term">本サービスに関する当社から利用者への通知等は本サービスにかかるウェブサイト上への掲示のほか、その他当社が適当と判断する方法によって行います。 </p>

                                <div class="pt-3 pb3">
                                第9条(商品の購入)
                                </div>

                                <p class="paragraph-term">1.利用者は、本サービスを利用して出店者より商品等を購入することができます。ただし、未成年の方は酒類商品等を購入できません。</p>
                                <p class="paragraph-term">2.利用者は、商品等の購入を希望する場合、出店者が指定する方法に従って商品の購入またはサービスの利用を申込むものとします。利用者は、申込内容につき真実かつ正確なデータを入力し、送信するものとします。</p>
                                <p class="paragraph-term">3.前項の申込に対して、出店者が承諾する場合、利用者が申請したメールアドレス宛に承諾する旨をメールで送信し、それが利用者に到達した時点をもって、売買契約の成立とさせていただきます。</p>
                                <p class="paragraph-term">4.前項の規定に拘わらず、本サービス利用に関して不正行為もしくは不適当な行為があった場合、または利用者指定のクレジットカード会社から与信不履行等の旨の連絡があった場合、当社は、売買契約を取消しもしくは解除、履行停止その他適切な措置を取ることができるものとします。</p>
                                <p class="paragraph-term">5.本サービスによる商品等の配送は、岐阜県多治見市内に限ります。</p>
                                <p class="paragraph-term">6.当社は以下各号のいずれかの場合、当社の判断で当該売買契約の取消、解除、その他適切な措置をとることができるものとします。</p>
                                <p class="paragraph-term">(1)不正行為または不適当な行為があった場合、またはこれを強く疑わせる事情があった場合</p>
                                <p class="paragraph-term">(2)届先不明等により容易にお届けできない場合</p>
                                <p class="paragraph-term">(3)お支払いが困難であると認める事情が判明した場合</p>

                                <div class="pt-3 pb-3">
                                第10条(決済方法)
                                </div>

                                <p class="paragraph-term">1.商品等のお支払金額は、商品購入代金、配送料および消費税の合計となります。</p>
                                <p class="paragraph-term">2.本サービスによって購入された商品等のお支払いに関しては、利用者本人名義のクレジットカードによるお支払い、出店者指定口座へのお振込みによるお支払い、宅配配送での代金(現金)引換えによるお支払い、または当社が別途認めるお支払方法を利用するものとします。 </p>
                                <p class="paragraph-term">3.クレジットカードでのお支払いの場合は、利用者がカード会社との間で別途契約する条件に従うものとします。なお、利用者と当該カード会社の間で紛争が発生した場合は、当該当事者双方で解決するものとします。</p>

                                <div class="pt-3 pb-3">
                                第11条(注文のキャンセル)
                                </div>

                                <p class="paragraph-term">商品注文確定後の返品・キャンセル等のトラブルに関して当社は責任を負いませんので、利用者は直接出店者とやり取りを行い、解決を行うものとします。</p>

                                <div class="pt-3 pb-3">
                                第12条(商品等の返品等)
                                </div>

                                <p class="paragraph-term">商品等に瑕疵(乱丁、落丁、破損、汚損)や品違い等を見つけた場合は直ちに出店者に通知した上で、出店者との間で返品方法を協議してください。なお、当社は本サービスの運営者であり返品等と直接受け付けることはありません。</p>

                                <div class="pt-3 pb-3">
                                第13条(個人情報の第三者提供)
                                </div>

                                <p class="paragraph-term">利用者は、本サービスの利用の際に提供した顧客情報をモールの運営、広告または宣伝のために必要な範囲で、出店者に提供されることに同意することとします。</p>
                                <p class="paragraph-term">また、利用者は本サービスの利用の際に提供した個人情報を、商品等の配送業務に必要な範囲で、配送事業者に提供されることに同意することとします。</p>

                                <div class="pt-3 pb-3">
                                第14条(情報の管理)
                                </div>

                                <p class="paragraph-term">当社は、利用者がサービスの利用に関して行ったコメントその他の情報(個人を特定する情報は別途規定するプライパシーポリシーに従います)について、利用者に断りなく当社あるいは出店者において利用できるものとし、次の各号に該当する場合には、利用者に断りなくこれを削除することができるものとします。</p>
                                <p class="paragraph-term">(1)当該情報が当社もしくは第三者の著作権その他の権利を明らかに侵害し、または当社もしくは第三者の名誉もしくは信用を明らかに毀損していると認められた場合</p>
                                <p class="paragraph-term">(2)日本または適用ある外国の法令に明らかに違反していると認められた場合</p>
                                <p class="paragraph-term">(3)官公庁、裁判所等の公的機関から、法律に基づき削除するよう命令(仮処分命令を含む。)を受けた場合</p>

                                <div class="pt-3 pb-3">
                                第15条(免責)
                                </div>

                                <p class="paragraph-term">1.出店者との取引内容・取扱商品・サービス・ページ上の記載内容・個人情報の取扱等は、出店者に直接お問合せください。利用規約における当社の責任は合理的な努力をもって本サービスを運営することに限られるものとし、当社は商品等に関する内容の真偽、正確性、最新性、有用性、信頼性、適法性、第三者の権利を侵害していないことなどについて一切責任を負わず、またいかなる保証もいたしません。</p>
                                <p class="paragraph-term">2.通信回線やコンピュータ等の障害によるシステムの遅延・変更・中断・中止・データの消失、データへの不正アクセス等により生じた損害、その他利用者に生じた損害について当社は一切責任を負わないものとします。</p>
                                <p class="paragraph-term">3.利用者が利用規約等に違反したことによって生じた損害については、当社は一切責任を負わないものとします。</p>
                                <p class="paragraph-term">4.当社は、利用者による本サービスの利用に関連して、利用者に対する責任を負う場合には、当社の故意または重大な過失による場合を除き、利用者に現実に生じた通常かつ直接の範囲の損害に限り、これを賠償します。</p>

                                <div class="pt-3 pb-3">
                                第16条(会員の登録)
                                </div>

                                <p class="paragraph-term">1.利用規約に同意の上、所定の入会申込み手続をされた個人は、登録手続完了後に会員としての資格を有します。ただし、以下の各号のいずれかの事由に該当する場合は、登録および再登録を拒否する場合があり、またその理由については一切開示義務を負いません。</p>
                                <p class="paragraph-term">(1)過去に会員資格が取り消された場合</p>
                                <p class="paragraph-term">(2)サービスを悪用するおそれや、第三者に迷惑をかけたりするおそれがある場合</p>
                                <p class="paragraph-term">(3)当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合</p>
                                <p class="paragraph-term">(4)未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合.</p>
                                <p class="paragraph-term">(5)その他、当社が登録に相応しくないと判断した場合</p>
                                <p class="paragraph-term">2.会員登録手続は、会員となるご本人が行ってください。代理による登録は一切認められません。</p>
                                <p class="paragraph-term">3.会員登録手続の際には、入力上の注意をよく読み、所定の入力フォームに必要事項を正確に入力してください。</p>

                                <div class="pt-3 pb-3">
                                第17条(パスワード管理)
                                </div>

                                <p class="paragraph-term">パスワードは、第三者に知られることがないよう定期的に変更する等、会員本人が責任をもって管理しなければなりません。それらが盗用、不正利用その他の事情により会員以外の者が利用した場合であっても、それにより生じた損害について当社は一切責任を負わないものとします。</p>

                                <div class="pt-3 pb-3">
                                第18条(会員情報の変更)
                                </div>

                                <p class="paragraph-term">1.会員は、氏名、住所など当社に届け出た事項に変更があった場合には、直ちに本サービスのマイページより変更手続きをするものとします。</p>
                                <p class="paragraph-term">2.変更登録がなされなかったことにより会員に生じた損害について、当社および出店者は一切責任を負いません。また、変更登録がなされた場合でも、変更登録前にすでに手続がなされた取引は、変更登録前の情報に基づいて行われますのでご注意ください。</p>

                                <div class="pt-3 pb-3">
                                第19条(退会)
                                </div>

                                <p class="paragraph-term">1.会員が退会を希望する場合には、会員本人が退会手続きを行ってください。所定の退会手続の終了後に、退会となります。</p>
                                <p class="paragraph-term">2.退会にあたり、本サービスの出店者に対して負っている債務がある場合は、会員は、本サービスの出店者に対して負っている債務の一切について当然に期限の利益を失い、直ちに本サービスの出店者に対してすべての債務の支払を行わなければなりません。</p>

                                <div class="pt-3 pb-3">
                                第20条(会員資格の喪失および賠償義務)
                                </div>

                                <p class="paragraph-term">1.当社は、会員が以下の各号のいずれかの事由にあたる場合、会員資格を一時的に停止、または取り消すことができることとします。</p>
                                <p class="paragraph-term">(1)利用規約のいずれかの条項に違反した場合</p>
                                <p class="paragraph-term">(2)会員が、第6条1項各号に定める行為をした場合</p>
                                <p class="paragraph-term">(3)通信販売による代金支払債務の履行を怠った場合</p>
                                <p class="paragraph-term">(4)その他、当社が本サービスの利用、会員資格の保有の継続を適当でないと判断した場合</p>
                                <p class="paragraph-term">2.前項に該当し、会員資格を一時的に停止、または取り消しとなった際、本サービスの出店者に対して負っている債務がある場合、会員は本サービスの出店者に対して負っている債務の一切について当然に期限の利益を失い、直ちに本サービスの出店者に対してすべての債務の支払を行わなければなりません。</p>
                                <p class="paragraph-term">3.第1項に該当し、会員資格を一時的に停止、または取り消しとなった場合でも当社は、本条に基づき当社が行った行為により会員に生じた損害について一切の責任を負いません。</p>

                                <div class="pt-3 pb-3">
                                第21条(準拠法)
                                </div>

                                <p class="paragraph-term">利用規約に関する準拠法は、全て日本国の法令が適用されるものとします。</p>

                                <div class="pt-3 pb-3">
                                第22条(協議および管轄裁判所)
                                </div>

                                <p class="paragraph-term">1.利用規約の解釈を巡って疑義が生じた場合、当社は合理的な範囲でその解釈を決定できるものとします。</p>
                                <p class="paragraph-term">2.利用規約に関する全ての紛争については、名古屋地方裁判所を第一審の専属的合意管轄裁判所といたします。</p>
                            </div>
                            <div>
                                <div class="pt-4 pb-4">
                                プライバシーポリシー
                                </div>
                                <span class="paragraph-term">
                                    株式会社トーカイ薬局は、個人情報保護の重要性に鑑み、「個人情報の保護に関する法律」及び本プライバシーポリシーを遵守し、お客さまのプライバシー保護に努めます。
                                </span>

                                <div class="pt-3 pb-3">
                                    個人情報の定義
                                </div>

                                <p class="paragraph-term">お客さま個人に関する情報(以下「個人情報」といいます)であって、お客さまのお名前、住所、電話番号など当該お客さま個人を識別することができる情報をさします。他の情報と組み合わせて照合することにより個人を識別することができる情報も含まれます。</p>


                                <div class="pt-3 pb-3">プライバシーポリシー　 （個人情報保護宣言） （インターネットを利用したショッピングモール業務）</div>

                                <div class="pt-3 pb-3">当社は、お客様から安心して情報を提供いただき有効に利用させていただくため、個人情報保護に関する基本方針としてこのプライバシーポリシーを制定し、すべての役職員がこれを遵守し適正な取扱と安全管理の徹底を図っていくことを宣言します。</div>

                                <div class="pt-4 pb-4">1.個人情報の取得・利用について</div>


                                <p class="paragraph-term">①当社は、お客様の個人情報を適切かつ適法な手段により取得し、公表する利用目的［インターネットを利用したショッピングモール（以下ＥＣモール）業務］に基づき、利用させていただきます。利用目的の達成に必要な範囲を超えた利用はいたしません。</p>
                                <p class="paragraph-term">②利用目的の具体的内容は、インターネットホームページへの掲載にて公表のうえ、契約書その他の書面にてお客様から直接個人情報を取得する際はその利用目的を明示いたします。</p>
                                <p class="paragraph-term">③当社では、例えば、以下のような情報源から、お客様の個人情報を取得いたします。</p>
                                <p class="paragraph-term">・ECモールの会員登録ページ等お客さまに登録いただくフォームにて提供される場合</p>
                                <p class="paragraph-term">・ECモールの出店申込書等の書面にて提供される場合</p>
                                <p class="paragraph-term">④当社はお客さまにとって利用目的が明確になるよう具体的に定めるとともに、例えば「各種アンケート等の集計のためのみに利用します」等取得の場面に応じ、利用目的を限定するよう努めます。</p>
                                <p class="paragraph-term">⑤当社はダイレクトメールの送付や電話等での勧誘のダイレクトマーケティングで個人情報を利用することについて、お客様さまより中止のお申し出があった場合は、当該目的での個人情報の利用を中止いたします。</p>
                                <p class="paragraph-term">⑥当社では例えば以下のような場合に個人データの取扱いの委託を行っています。</p>
                                <p class="paragraph-term">・情報システムの運用、保守に関わる業務</p>
                                <p class="paragraph-term">・キャンペーン商品等の発送に関わる業務</p>

                                <div class="pt-3 pb-3">2.個人データの第三者への提供について</div>

                                <p class="paragraph-term">当社は、法令等により認められる場合又はご利用規約に定める場合を除き、お客様の同意をいただくことなくお客様の個人データを第三者に提供することはありません。</p>
                                <p class="paragraph-term">ただし、下記に定める事項の場合は、第三者に該当しないものとして、お客様の同意をいただくことなくお客様の個人データを提供することがあります。</p>

                                <div class="pt-3 pb-3">3.個人データの委託</div>

                                <p class="paragraph-term">当社は、利用目的の達成に必要な範囲内において、お客様の個人データの取扱を外部に委託することがあります。 個人データの 共同利用 ①当社は、利用目的の達成に必要な範囲内において、お客様の個人データを共同利用することがあります。 ②具体的な内容は、インターネットホームページへ掲載しております。 3.センシティブ情報の取扱について 当社は、個人情報の保護に関する法律(以下「法」)第2条第3項に定める要配慮個人情報ならびに労働組合への加盟、門地、本籍地、保険医療および性生活(これらのうち要配慮個人情報に該当するものを除く)に関する情報 ＜本人、国の機関、地方公共団体、法第76条第1項各号もしくは個人情報の保護に関する法律施行規則第6条各号に掲げる者により公開されているもの、または本人を目視し、もしくは撮影することにより取得するその外形上明らかなものを除く。以下いわゆる「機微(センシティブ)情報」＞ については、法令等に基づく場合や適切な業務運営を確保する必要性からお客様の同意をいただいた場合等を除き、原則として取得・利用・第三者提供はいたしません。 ＜要配慮個人情報＞ 本人の人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実その他本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱に特に配慮を要するものとして政令で定める記述等が含まれる個人情報をいう。</p>

                                <div class="pt-3 pb-3">4.個人データの安全管理について</div>


                                <p class="paragraph-term">当社は情報資産管理責任者のもとで、個人データの漏洩、改ざん、紛失、破壊等に対する安全管理のため、個人データへのアクセス管理、持出の制限、外部からの不正アクセス防止などの措置を講じたうえで、定期的な点検、監査を実施し、継続的に態勢の見直しを行ってまいります。</p>
                                <p class="paragraph-term">当社は役職員ひとりひとりの意識を継続的に徹底させ個人情報の保護に対する自覚と責任をもつことが重要な課題であると認識しており、必要な教育計画を定め、すべての役職員に対してこれを実施し啓蒙を図ってまいります。</p>
                                <p class="paragraph-term">当社は、お客様からご提供いただいた個人情報の外部委託を行う場合には、委託先の適切な選定を行い、委託先に対して必要かつ適切な監督を行ってまいります。</p>

                                <div class="pt-3 pb-3">5.遵守すべき法令、規範について</div>


                                <p class="paragraph-term">当社は、個人情報の保護に関する法律などの諸法令およびこれを適切に運用するために告示された各省庁のガイドラインや業界自主ルール等を遵守し、これらを取りまとめた社内規程に基づき全役職員一丸となって個人情報の保護に取組んでまいります。</p>

                                <div class="pt-3 pb-3">6.個人情報保護に対する継続的改善</div>


                                <p class="paragraph-term">当社は、個人情報の保護を適切に行うため、社会情勢の変化、法令等の改正、点検、監査の実施結果に基づき、このプライバシーポリシー、社内規程、運用管理体制等の見直しを継続的に行い、改善を図ってまいります。</p>

                                <div>7.保有個人データの開示等の手続について</div>


                                <p class="paragraph-term">①当社は、お客様からご自身に関する保有個人データの情報開示等の依頼があった場合は、ご本人様であることを確認させていただいたうえで、法令の定めに従いお答えいたします。具体的な内容については、「お客様の個人情報の取扱について」と題してインターネットホームページにおいて公表いたしますので、内容をご確認のうえ当社までご連絡ください。なお、ご請求には一定の手数料が必要になりますので、ご了承ください。</p>
                                <p class="paragraph-term">②お客様本人から、ご自身に関する保有個人データの内容が事実に反する等の理由による当該保有個人データの訂正、追加、削除または利用停止、消去、第三者提供の停止のご要望があった場合、必要な調査を行ったうえで誠実かつ迅速な対応を行うよう努めてまいります。</p>

                                <div class="pt-3 pb-3">8.個人情報の取扱に関する苦情等</div>

                                <p>当社は、個人情報の取扱に関する苦情等の問合せに適切かつ迅速に対応するため、「苦情等のお問い合わせ窓口」を設置し、誠意ある対応により苦情処理に対処いたします。</p>

                                <div class="pt-3 pb-3">お客様の個人情報の取扱について
                                （インターネットを利用したショッピングモール業務）</div>

                                <div class="pt-3 pb-3">当社は、「個人情報の保護に関する法律」（平成15年5月30日法律第57号）に基づき、お客様の個人情報の取扱について下記のとおり公表いたします。</div>

                                <div class="pt-3 pb-3">１．個人情報の取得・利用について</div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">当社は、お客様の個人情報を適切かつ適法な手段により取得し、下記業務において、下記利用目的の範囲内において利用させていただきます。</p>
                                <p class="paragraph-term">業務内容</p>
                                <p class="paragraph-term">インターネットを利用したショッピングモール（以下ECモール）の管理運営業務</p>
                                <p class="paragraph-term">利用目的</p>
                                <p class="paragraph-term">①サービスへのログインおよびサービスの利用（お客様の同意に基づく利用またはお客様との契約の履行のための利用</p>
                                <p class="paragraph-term">・ お客様がサービスにログインするため</p>
                                <p class="paragraph-term">・ お客様に適したサービスを提供・運用するため</p>
                                <p class="paragraph-term">・ お客様と当社グループとの間の取引の成立および履行その他のお客様によるサービスの利用のため</p>
                                <p class="paragraph-term">・ サービスの会員であるお客様の管理のため</p>
                                <p class="paragraph-term">・ サービスの商品等の梱包・発送業務のため</p>
                                <p class="paragraph-term">・ サービスの対価の請求のため</p>
                                <p class="paragraph-term">・ ポイントサービスの運用のため</p>
                                <p class="paragraph-term">・ サービスの運営上必要な事項の通知のため</p>
                                <p class="paragraph-term">・ サービスの各種問合わせ、アフターサービス対応のため</p>
                                <p class="paragraph-term">・ 退会したお客様へのご連絡・各種問合わせ対応のため</p>
                                <p class="paragraph-term">・ 不正行為等の防止および対応のため</p>
                                <p class="paragraph-term">・ 当社グループが実施するサービス又は企画に関する連絡のため</p>
                                <p class="paragraph-term">・ ユーザーの本人認証及び各種画面における登録情報の自動表示</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">②広告・マーケティングのための利用（お客様の同意に基づく利用）</p>
                                <p class="paragraph-term">・ サービス上または第三者の媒体においてサービスの勧誘、広告その他のマーケティングをするため</p>
                                <p class="paragraph-term">・ メールマガジンの送信のため</p>
                                <p class="paragraph-term">・ ダイレクトメールの送付のため</p>
                                <p class="paragraph-term">・ キャンペーン、懸賞企画、アンケートの実施のため</p>
                                <p class="paragraph-term">・ サービス上または第三者の媒体において第三者が広告主となる広告を配信するため</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">③商品開発・研究のための利用（お客様との同意に基づく利用）</p>
                                <p class="paragraph-term">・ マーケティングデータの調査・分析のため</p>
                                <p class="paragraph-term">・ 当社グループとの新たなサービスや商品等の開発のため</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">④出店者に向けた販売促進コンサルティングのための利用</p>
                                <p class="paragraph-term">取得する個人情報の項目 ①サービスへのログインおよび利用等に関する情報</p>
                                <p class="paragraph-term">・ サービスログインのためのアカウントのIDおよびパスワード</p>
                                <p class="paragraph-term">・ アカウント登録情報としての性別、生年月日、郵便番号およびメールアドレス</p>
                                <p class="paragraph-term">・ アカウントに紐づく情報としてお客様から提供される、氏名、住所、電話番号</p>
                                <p class="paragraph-term">・ ポイント、クーポン、その他のサービスの利用に関する情報</p>
                                <p class="paragraph-term">・ サービスの利用を通じてお客様が行った取引の遂行のための情報および当該取引内容に関する情報</p>
                                <p class="paragraph-term">・電子マネー、収納代行、代引きに関する情報その他の決済およびその方法に関する情報</p>
                                <p class="paragraph-term">・ お客様から当社へのお問い合わせやご連絡等に関する情報</p>
                                <p class="paragraph-term">・ 法律上の要請等により、本人確認を行うための本人確認書類（運転免許証、健康保険証、住民票の写し等のことをいいます。）および当該書類に含まれる情報</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">⑤ お客様が使用する端末に関する情報およびCookie等を利用して取得する情報</p>
                                <p class="paragraph-term">・ 情報通信端末の機体識別に関する情報</p>
                                <p class="paragraph-term">・ 情報通信端末のOS情報</p>
                                <p class="paragraph-term">・ インターネットへの接続に関する情報</p>
                                <p class="paragraph-term">・ 位置情報</p>
                                <p class="paragraph-term">・ リファラー情報</p>
                                <p class="paragraph-term">・ IPアドレス情報</p>
                                <p class="paragraph-term">・ 閲覧したURLおよび閲覧した日時に関するタイムスタンプ情報</p>
                                <p class="paragraph-term">・ サービスの利用に関する情報</p>
                                <p class="paragraph-term">・ お客様の端末ごとに割り振られる広告識別子情報</p>
                                <p class="paragraph-term">・ サーバログ情報</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">⑥第三者から取得する情報</p>
                                <p class="paragraph-term">・ お客様の同意を得て第三者から取得する情報</p>
                                <p class="paragraph-term">・ 法令に基づき取得する情報</p>
                                <p class="paragraph-term">・ 公開されていることにより取得する情報</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <div class="pt-3 pb-3">サービスの利用に関連して当社が取得する情報の全部又は一部をご提供いただけない場合は、サービスの利用をお断りする場合がございますので、あらかじめご了承下さい。</div>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">その他</p>
                                <p class="paragraph-term">①当社は、あらかじめ利用目的を明確にして個人情報を取得いたします。また、業務の適切な運営から、お客様本人の個人情報取得に加え、法人先との取引においては法人代表者の氏名等の個人情報を取得する場合がございます。</p>
                                <p class="paragraph-term">②当社は、下記のとおり特定の個人情報の利用目的が法令等に基づき限定されている場合には、当該利用目的以外で利用いたしません。</p>
                                <p class="paragraph-term">③当社は、個人情報の保護に関する法律(以下「法」という)第2条第3項に定める要配慮個人情報ならびに労働組合への加盟、門地、本籍地、保険医療および性生活(これらのうち要配慮個人情報に該当するものを除く)に関する情報(本人、国の機関、地方公共団体、法第76条第1項各号もしくは個人情報の保護に関する法律施行規則第6条各号に掲げる者により公開されているもの、または本人を目視しもしくは撮影することにより取得するその外形上明らかなものを除く。いわゆる「機微(センシティブ)情報」)については法令等に基づく場合や適切な業務運営を確保する必要性からお客様の同意をいただいた場合等を除き、原則として取得・利用・第三者提供はいたしません。</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">＊要配慮個人情報</p>
                                <p class="paragraph-term">本人の人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実その他本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱いに特に配慮を要するものとして政令で定める記述等が含まれる個人情報をいう。</p>

                                </div>

                                <!-- <div class="pt-3 pb-3">
                                <p class="paragraph-term">業務内容</p>
                                <p class="paragraph-term">ホームページの作成支援業務および運営、管理およびこれらに関するコンサルティング業務</p>
                                <p class="paragraph-term">利用目的</p>
                                <p class="paragraph-term">①サービスへのログインおよびサービスの利用（お客様の同意に基づく利用またはお客様との契約の履行のための利用）</p>
                                <p class="paragraph-term">・ お客様がサービスにログインするため</p>
                                <p class="paragraph-term">・ お客様に適したサービスを提供・運用するため</p>
                                <p class="paragraph-term">・ サービスの対価の請求のため</p>
                                <p class="paragraph-term">・ サービスの運営上必要な事項の通知のため</p>
                                <p class="paragraph-term">・ サービスの各種問合わせ、アフターサービス対応のため</p>
                                <p class="paragraph-term">・ 不正行為等の防止および対応のため</p>
                                <p class="paragraph-term">②広告・マーケティングのための利用（お客様の同意に基づく利用）</p>
                                <p class="paragraph-term">・ サービス上または第三者の媒体においてサービスの勧誘、広告その他のマーケティングをするため</p>
                                <p class="paragraph-term">③商品開発・研究のための利用（お客様との同意に基づく利用）</p>
                                <p class="paragraph-term">・ マーケティングデータの調査・分析のため</p>
                                <p class="paragraph-term">・ 当社グループとの新たなサービスや商品等の開発のため取得する個人情報の項目 サービスへのログインおよび利用等に関する情報</p>
                                <p class="paragraph-term">・サービスログインのためのアカウントのIDおよびパスワード</p>
                                <p class="paragraph-term">・当社の提供するホームページ環境に入力された個人情報</p>
                                <p class="paragraph-term">・サービスの利用、申込に際しお客様から提供される、氏名、住所、電話番号、郵便番号、メールアドレス</p>
                                <p class="paragraph-term">・ 法律上の要請等により、本人確認を行うための本人確認書類（運転免許証、健康保険証、住民票の写し等のことをいいます。）および当該書類に含まれる情報</p>
                                <p class="paragraph-term">その他</p>
                                <p class="paragraph-term">①当社は、あらかじめ利用目的を明確にして個人情報を取得いたします。また、業務の適切な運営から、お客様本人の個人情報取得に加え、法人先との取引においては法人代表者の氏名等の個人情報を取得する場合がございます。</p>
                                <p class="paragraph-term">②当社は、下記のとおり特定の個人情報の利用目的が法令等に基づき限定されている場合には、当該利用目的以外で利用いたしません。</p>
                                <p class="paragraph-term">③当社は、個人情報の保護に関する法律(以下「法」という)第2条第3項に定める要配慮個人情報ならびに労働組合への加盟、門地、本籍地、保険医療および性生活(これらのうち要配慮個人情報に該当するものを除く)に関する情報(本人、国の機関、地方公共団体、法第76条第1項各号もしくは個人情報の保護に関する法律施行規則第6条各号に掲げる者により公開されているもの、または本人を目視しもしくは撮影することにより取得するその外形上明らかなものを除く。いわゆる「機微(センシティブ)情報」)については法令等に基づく場合や適切な業務運営を確保する必要性からお客様の同意をいただいた場合等を除き、原則として取得・利用・第三者提供はいたしません。</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">＊要配慮個人情報</p>
                                <p class="paragraph-term">本人の人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実その他本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱いに特に配慮を要するものとして政令で定める記述等が含まれる個人情報をいう。</p>

                                </div> -->

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">２．個人データの第三者への提供について</p>
                                <p class="paragraph-term">当社は、法令等により認められる場合を除き、あらかじめお客様の同意をいただくことなくお客様の個人データを第三者に提供することはありません。個人情報保護に関する法律第23条第1項によりあらかじめお客様の同意をいただくことなく第三者提供が認められる事項は下記の事項です。</p>
                                <p class="paragraph-term">①法令に基づく場合</p>
                                <p class="paragraph-term">②人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</p>
                                <p class="paragraph-term">③公衆衛生の向上または児童の健全な育成の推進のため特に必要がある場合であって本人の同意を得ることが困難であるとき</p>
                                <p class="paragraph-term">④国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがあるとき</p>

                                </div>

                                <div class="pt-3 pb-3">
                                    <p class="paragraph-term">３．保有個人データの開示等の手続きについて</p>
                                    <p class="paragraph-term">申出先</p>
                                    <p class="paragraph-term">当社窓口にお申し出ください。郵送による請求をご希望の場合は、手続関係書類を送付させていただきますので、電話等でお問い合わせください。</p>
                                    <p class="paragraph-term">手続きの種類</p>
                                    <p class="paragraph-term">①利用目的の通知の求め</p>
                                    <p class="paragraph-term">②開示の求め</p>
                                    <p class="paragraph-term">③内容の訂正、追加または削除の求め</p>
                                    <p class="paragraph-term">④利用停止または消去の求めおよび第三者への提供停止の求め</p>
                                    <p class="paragraph-term">請求が可能な方 本人様または代理権が確認できる代理人様とする。</p>
                                    <p class="paragraph-term">提出書類</p>
                                    <p class="paragraph-term">ご提出していただく書類は、ご請求の方法等により異なります。当社窓口にお申し出ください。</p>
                                    <p class="paragraph-term">回答の方法</p>
                                    <p class="paragraph-term">本人様ご来店または本人様宛郵便にて文書で回答させていただきます。</p>
                                    <p class="paragraph-term">手数料</p>
                                    <p class="paragraph-term">①当社が定める基本事項を求められる場合は1回当たり540円の手数料を現金にて申受けます。また、郵送の場合は別途郵送料を申受けます。</p>
                                    <p class="paragraph-term">②詳しくは、当社窓口にてご確認ください。</p>
                                    <p class="paragraph-term">回答期間</p>
                                    <p class="paragraph-term">受付した日より起算して原則10日以内を目途に対応させていただきます。</p>
                                    <p class="paragraph-term">請求に応じられない場合</p>
                                    <p class="paragraph-term">下記に定める場合等は請求に応じられないことがありますが、手数料はご返却できませんので、あらかじめご了承ください。</p>
                                    <p class="paragraph-term">①開示の求めの対象が「保有個人データ」に該当しない場合</p>
                                    <p class="paragraph-term">②本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</p>
                                    <p class="paragraph-term">③当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</p>
                                    <p class="paragraph-term">④他の法令等に違反することとなる場合</p>
                                    <p class="paragraph-term">その他開示等の手続について、ご不明な点がございましたら当社窓口にお申し出ください。</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">４.当社は、以下のとおり個人情報を共同利用します。</p>
                                <p class="paragraph-term">①共同して利用される個人情報の項目</p>
                                <p class="paragraph-term">第1条に記載した項目</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">②共同して利用する者の範囲</p>
                                <p class="paragraph-term">・ECモールの加盟店</p>
                                <p class="paragraph-term">・配送事業者</p>

                                </div>

                                <div class="pt-3 pb-3">
                                <p class="paragraph-term">③利用する者の利用目的</p>
                                <p class="paragraph-term">第1条に記載した利用目的</p>
                                </div>

                                <div class="pb-3 pb-3">
                                    <p class="paragraph-term">④共同利用する個人情報の管理について責任を有する者</p>
                                    <p class="paragraph-term">株式会社トーカイ薬局</p>
                                </div>

                                <div class="pt-3 pb-3">
                                    <p class="paragraph-term">５．個人情報の取扱に関する苦情等受付の窓口について</p>
                                    <p class="paragraph-term">当社は、個人情報の取扱に関する苦情等の問合せに適切かつ迅速に対応するため、下記窓口を設置しておりますので、ご意見、ご要望等がございましたらお申し出ください。</p>
                                    <p class="paragraph-term">誠意をもって対応させていただきます。</p>
                                </div>

                                <div class="pt-3 pb-3">
                                    <p class="paragraph-term">株式会社トーカイ薬局EC事業部</p>
                                    <p class="paragraph-term">電話番号: 0570-030-305</p>
                                    <p class="paragraph-term">e-mail: info@tajimingo.com</p>
                                </div>
                            </div>
                        </v-container>
                    </div>
                    <div class="d-flex flex-row justify-end align-end" @click.prevent="showAllTerm" style="cursor: pointer">
                        <v-icon color="black" size="25">
                            mdi-chevron-right
                        </v-icon>
                        <p style="font-size: 15px">{{ $t("message.label-click-all-term") }}</p>
                    </div>
                </div>
            </div>
        </v-container>
        <div class="mt-4">
            <v-form @click.prevent="goToRegister">
                <div class="d-flex flex-row justify-center align-center">
                    <v-checkbox
                        v-model="checkbox"
                        label="利用規約に同意する"
                        >
                        </v-checkbox>
                </div>
                    <div style="margin-left: 10px; margin-top: -10px">
                        <span v-if="!checkbox && clicked" style="color: red; font-size: 12px">{{ $t("message.label-required-term") }}</span>
                    </div>
                <div class="d-flex flex-row justify-center align-center me-0 pt-6 pb-6" tile style="background-color: #D5D5D5">
                    <div class="m-2">
                        <v-btn depressed color="#E93B81" type="submit" @click.prevent="goToRegister" height="45" width="250">
                        <span style="color: white">{{$t("message.label-register")}}</span>
                        </v-btn>
                    </div>
                </div>
            </v-form>
        </div>
        <v-fab-transition v-if="showTerm && scroll">
            <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#ff0090"
                id="fixedbutton"
                style="height: 50px; width: 50px"
                @click="toButtomPage"
                >
                <v-icon dark size="40">
                    mdi-chevron-down
                </v-icon>
            </v-btn>
        </v-fab-transition>
        <v-fab-transition v-if="showTerm && !scroll">
            <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#ff0090"
                id="fixedbutton"
                style="height: 50px; width: 50px"
                @click="toTopPage"
                >
                <v-icon dark size="40">
                    mdi-chevron-up
                </v-icon>
        </v-btn>
        </v-fab-transition>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
    name: "TajimiTermOfService",
    mixins: [validationMixin],
    validations: {
        checkbox: { required }
    },
    data() {
        return {
            checkbox: false,
            clicked: false,
            showTerm: false,
            scroll: false
        }
    },
    methods: {
        // handleScrollTerm() {
        //     this.scrollToEnd()
        // },
        // scrollToEnd() {
        //     var container = this.$el.querySelector("#container");
        //     container.scrollTop = container.scrollHeight;
        // },
        handleScroll(event) {
            // CONDITIONAL IF SCROLL TO BOTTOM PAGE
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {                
                this.scroll = false
            }
            // CONDITIONAL IF SCROLL TO TOP PAGE
            else if (window.scrollY == 0) {
                this.scroll = true
            }
        },
        showAllTerm() {
            this.scroll = true
            this.showTerm = !this.showTerm // true
        },
        toButtomPage() {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth"
            })
            this.scroll = false
        },
        toTopPage() {
            window.scrollTo({
                behavior: "smooth",
                top: 0
            })
            this.scroll = true
        },
        goToRegister() {
            this.clicked = true
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString)
            const token = urlParams.get('token')

            let decode_token = atob(token)
            let arr_decodetoken = decode_token.split("+")

            if(this.checkbox && this.clicked) {
                localStorage.setItem('token', token)
                localStorage.setItem('email', arr_decodetoken[0])
                this.$router.push('/register')
            } else {
                return
            }
        },
    },
    computed: {
    },
    created() {
        window.addEventListener('scroll', this.handleScroll)
        // this.showAllTerm()
    },
    destroyed() {
        window.addEventListener('scroll', this.handleScroll)
    }
}
</script>

<style>
#fixedbutton {
    position: fixed;
    bottom: 3em;
    right: 1em;
}
.Tos-layout {
    border: 1px solid black;
    width: 100%;
    height: 14vh;
    overflow-x: scroll;
}
.Tos-all-term {
    border: 1px solid black;
    width: 100%;
    height: auto;
}
.text-term {
    word-break: break-all;
    font-size: 14px;
}
.paragraph-term {
    white-space: pre-line
}
</style>